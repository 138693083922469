import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import Button from '../../button';
import { GovermnetSourcesItem } from './GovermentSourcesItem';
import { OpenSourcesItem } from './OpenSourcesItem';
import { ReactComponent as ArchiveIcon } from '../../../icons/archiveIcon.svg';
import { ReactComponent as AddIcon } from '../../../icons/addIcon.svg';
import { useDialog } from '../../../providers/dialog.provider';
import { useCallback } from 'react';
import { AddSupplier } from './modals/AddSupplier';
import {
  addKtruProduct,
  restartProductSource,
  saveSourceProduct,
  updateProduct,
} from '../../../store/actions/organizations';
import { createErrorNotification, createNotification } from '../../../utils/notifications';
import { ConfirmModal } from '../../modal/ConfirmModal';
import { ChangeKTRU } from './modals/ChangeKTRU';
import { AddToArchive } from './modals/AddToArchive';
import { useSelector } from 'react-redux';
import { useContext } from 'react';
import { FeatureFlags } from '../../../providers/feature.provider';
import { productsAPIs, projectsAPIs, reportsAPIs } from '../../../services';

const MAX_SELECTED_SOURCES = 3;

export const Sources = ({ product, sources, KTRUList, onSavePrice, handleProductReload }) => {
  const [openSources, setOpenSources] = useState([]);
  const [governmentSources, setGovernmentSources] = useState([]);

  const features = useContext(FeatureFlags);
  const { role } = useSelector((state) => state.auth.user);
  const dialog = useDialog();
  const [currentTab, setCurrentTab] = useState('1');
  const [selectedSources, setSelectedSources] = useState(product?.selected_sources);

  useEffect(() => {
    setOpenSources(sources?.filter((source) => source.type === 'yandex_search' || source.type === 'engine_search'));
    setGovernmentSources(sources.filter((source) => source.type === 'zakupki'));
  }, [sources]);

  const report = useSelector((state) => state?.organizations?.selectedReport);
  const handleTabChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleSelectSource = (checked, id) => {
    if (checked) {
      setSelectedSources((prev) => [...prev, id]);
    } else {
      const updatedSelectedSources = selectedSources.filter((item) => item !== id);
      setSelectedSources(updatedSelectedSources);
    }
  };


  const getCorrectSources = (sources) => {
    setOpenSources(sources?.filter((source) => source.type === 'yandex_search' || source.type === 'engine_search'));
    setGovernmentSources(sources.filter((source) => source.type === 'zakupki'));
  };

  const handleAddSupplier = useCallback(
    async (price, url) => {
      const link = url.includes('http') ? url : `http://${url}`;
      const newSource = {
        source_price: parseInt(price),
        source_url: link,
        report_id: report?.id,
      };
      await productsAPIs
        .addSourceToProduct(product.id, newSource)
        .then((res) => {
          createNotification('Источник добавлен');
          getCorrectSources(res.sources);
        })
        .catch((err) => {
          console.error(err);
          createErrorNotification('Ошибка при добавлении источника');
        });
    },
    [product?.id, report?.id]
  );

  const handleRestartProductSource = (link) => {
    if (!link) {
      createErrorNotification('Что-то пошло не так');
      return;
    }
    return restartProductSource(product.id, link);
  };

  const handleKTRUSearch = useCallback(async () => {
    await addKtruProduct(product.id)
      .then(() => createNotification('ОКПД2/КТРУ успешно найдены', { type: 'success' }))
      .catch((err) => {
        console.error(err);
        createErrorNotification('Произошла ошибка при поиске ОКПД2/КТРУ');
      });
  }, [product]);

  const showAddSupplierModal = useCallback(() => {
    dialog.open(<AddSupplier onSubmit={handleAddSupplier} />);
  }, [dialog, handleAddSupplier]);

  const handleDeleteSupplier = useCallback(
    async (supplierId) => {
      await updateProduct(product.id, {
        sources: sources.filter((source) => source.id !== supplierId),
      });
    },
    [sources, product?.id]
  );

  const handleSaveSource = (link) => {
    return saveSourceProduct(product.id, link);
  };

  const showConfirmationModal = useCallback(
    (item) => {
      dialog.open(
        <ConfirmModal
          value={{ id: item?.id }}
          successMessage="Источник удалён"
          title={`Вы уверены, что хотите удалить источник ${item?.url}`}
          onAgree={handleDeleteSupplier}
        />
      );
    },
    [dialog, handleDeleteSupplier]
  );

  const showKTRUModal = useCallback(() => {
    dialog.open(
      <ChangeKTRU ktruList={product.ktru_list} choosenKtruList={product.show_ktru_list} productId={product.id} />
    );
  }, [dialog, product]);

  const showAddToArchiveModal = useCallback(
    (suppliers) => {
      dialog.open(<AddToArchive suppliers={suppliers} />);
    },
    [dialog]
  );

  const checkIfSourceSelected = (item) => {
    const isSelected = selectedSources.some((selectedId) => selectedId === item.id);
    const isReachedLimit = selectedSources.length >= MAX_SELECTED_SOURCES;

    return !isSelected && isReachedLimit;
  };

  useEffect(() => {
    const selectChoosenOpenSources = async () => {
      const body = {
        selected_indices_per_product: {
          [product.id]: selectedSources,
        },
      };
      await reportsAPIs
        .selectSources(report?.id, body)
        .then(() => {})
        .catch(() => {
          createErrorNotification('Произошла ошибка при выборе источника');
        });
    };
    if (JSON.stringify(product?.selected_sources) !== JSON.stringify(selectedSources)) {
      selectChoosenOpenSources();
    }
  }, [selectedSources, product, report?.id]);

  const handleUpdateDimensions = useCallback(
    async (body) => {
      await projectsAPIs
        .incorrectDimensions(body)
        .then(() => {
          createNotification('Характеристики товара обновлены');
          handleProductReload();
        })
        .catch((err) => {
          createErrorNotification('Произошла ошибка при обновлении характеристик');
        });
    },
    [handleProductReload]
  );
  return (
    <div className="tw-mb-4">
      <TabContext value={currentTab}>
        <div className="tw-border-b tw-border-[#DDD] ">
          <TabList onChange={handleTabChange}>
            <Tab
              label={`Открытые источники (${openSources?.length ?? 0})`}
              className="tw-normal-case tw-text-sm tw-font-medium tw-leading-[18px]"
              value={'1'}
              disableRipple
            />
            {/* <Tab
              label={`Государственные контракты (${governmentSources?.length ?? 0})`}
              className="tw-normal-case tw-text-sm tw-font-medium tw-leading-[18px]"
              value={'2'}
              disableRipple
            />
            <Tab
              label="Чат-бот"
              className="tw-normal-case tw-text-sm tw-font-medium tw-leading-[18px]"
              value={'3'}
              disableRipple
            /> */}
            {/* <Button
              variant="text"
              onClick={() => showAddToArchiveModal(product.sources)}
              className="tw-w-fit tw-ml-auto tw-flex tw-flex-row tw-items-center tw-gap-[3px] tw-p-2"
            >
              <ArchiveIcon /> Добавить в архив
            </Button> */}
          </TabList>
        </div>
        <TabPanel value="1" className="tw-p-0">
          <div className="tw-divide-y tw-divide-[#DDDDDD]">
            {openSources?.map((source) => (
              <OpenSourcesItem
                productName={product?.name}
                product_id={product?.id}
                project_id={report?.project_id}
                item={source}
                key={source?.id}
                onDelete={showConfirmationModal}
                onRestart={handleRestartProductSource}
                onSave={handleSaveSource}
                userRole={role}
                onEditPrice={onSavePrice}
                onChangeSelect={handleSelectSource}
                isDisabled={checkIfSourceSelected(source)}
                onUpdateDimensions={handleUpdateDimensions}
              />
            ))}
          </div>
          <Button
            variant="text"
            onClick={() => showAddSupplierModal()}
            className="tw-w-fit tw-p-0 tw-text-[#0844B4] tw-text-sm tw-leading-[18px] tw-font-semibold tw-flex tw-flex-row tw-gap-1 tw-items-center tw-px-2 tw-py-1"
          >
            <AddIcon />
            Добавить источник
          </Button>
        </TabPanel>
        {features.projectStatus === 'DEVELOPMENT' ? (
          <TabPanel value="2" className="tw-p-0 tw-pt-6">
            {KTRUList?.length === 0 && (
              <div className="tw-flex tw-flex-row tw-gap-[8px]">
                <p>Найденные ОКПД2/КТРУ</p>
                <Button
                  variant="text"
                  onClick={() => handleKTRUSearch()}
                  className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#0844B4] tw-font-medium tw-leading-[18px] tw-text-sm"
                >
                  Найти
                </Button>
              </div>
            )}
            {KTRUList?.length !== 0 && (
              <div className="tw-flex tw-flex-col tw-items-start tw-gap-3 tw-text-sm tw-mb-[10px]">
                <div className="tw-flex tw-flex-row tw-gap-[8px]">
                  <p>Найденные ОКПД2/КТРУ</p>
                  <Button
                    variant="text"
                    onClick={() => showKTRUModal()}
                    className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#0844B4] tw-font-medium tw-leading-[18px] tw-text-sm"
                  >
                    Изменить
                  </Button>
                </div>
                <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-2 tw-items-center">
                  {KTRUList.map((ktru) => (
                    <Tooltip
                      key={ktru.name}
                      title={ktru.name}
                      className="tw-bg-[#E6ECF7] tw-rounded-[4px] tw-py-2 tw-px-[10px] tw-cursor-pointer"
                    >
                      <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm">{ktru.code}</p>
                    </Tooltip>
                  ))}
                </div>
              </div>
            )}
            <div className="tw-divide-y tw-divide-[#DDDDDD]">
              {governmentSources.length > 0 &&
                governmentSources.map((source) => <GovermnetSourcesItem item={source} key={source.id} />)}
            </div>
          </TabPanel>
        ) : (
          <></>
        )}
        <TabPanel value="3" className="tw-p-0"></TabPanel>
      </TabContext>
    </div>
  );
};
