import cx from 'classnames';
import { Link } from 'react-router-dom';
import LogoIcon from '../../icons/logo';
import classes from './auth-layout.module.sass';

export default function AuthLayout({ children, bg }) {
  return (
    <div className={cx(classes.root, { [classes.white]: bg === 'white' })}>
      <div className={classes.left} style={{ backgroundImage: 'url(/images/background/loginbg.png)' }}>
        <div className={classes.leftWrapper}>
          <div className={classes.logoContainer}>
            <Link to="/">
              <LogoIcon />
            </Link>
          </div>
          <h2 className={classes.leftText}>Сервис предиктивной аналитики</h2>
          <div className={classes.tabletForm}>{children}</div>
        </div>
      </div>
      <div className={classes.right}>{children}</div>
    </div>
  );
}
