import { Dialog, Slider, TextField, useMediaQuery } from '@mui/material';
import { Field, Form } from 'react-final-form';
// import { ReactComponent as CloseIcon } from '../../icons/closeIcon.svg';
import Button from '../button';
import { useEffect, useState } from 'react';
import { userAPIs } from '../../services';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import { useSelector } from 'react-redux';

const RangeSelector = ({ label, startMarkLabel, endMarkLabel, fieldName }) => {
  const marks = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 9,
      label: '9',
    },
    {
      value: 10,
      label: '10',
    },
  ];
  return (
    <>
      <div className="tw-flex tw-flex-col tw-items-start tw-gap-[44px] tw-w-full">
        <p className="tw-text-[#191919] tw-leading-[18px] tw-text-sm tw-font-semibold">{label}</p>
        <div className="tw-flex tw-flex-col tw-w-full tw-px-[8.6px]">
          <Field name={fieldName}>
            {({ input: { value, onChange } }) => (
              <>
                <Slider
                  min={1}
                  max={10}
                  step={1}
                  marks={marks}
                  defaultValue={1}
                  onChange={(e, value) => onChange(value)}
                  sx={{
                    '& .MuiSlider-markLabel': {
                      top: '-17px',
                      '&[data-index="0"]': {
                        transform: 'translateX(0)',
                      },
                      '&[data-index="9"]': {
                        transform: 'translateX(-100%)',
                      },
                    },
                    '&.MuiSlider-root': {
                      marginBottom: '0px',
                      padding: '13px 0 16px',
                    },
                    '& .MuiSlider-thumb': {
                      width: '17px',
                      height: '17px',
                      backgroundColor: '#FFF',
                      border: '4px solid #0844B4',
                      borderRadius: '50%',
                      '&:before': {
                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                      },
                      '&:hover, &.Mui-focusVisible, &.Mui-active': {
                        boxShadow: 'none',
                      },
                    },
                  }}
                />
              </>
            )}
          </Field>

          <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-text-[#666666] tw-text-xs sm:tw-text-sm tw-leading-[18px]">
            <p>{startMarkLabel}</p>
            <p>{endMarkLabel}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export const FeedbackModal = () => {
  const mobile = useMediaQuery('(max-width: 500px)');
  const [isSurveyPassed, setIsSurveyPassed] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const organizationId = localStorage.getItem('organizationId');
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const checkLocalStorage = () => {
      const showFeedbackSurvey = sessionStorage.getItem('showFeedbackSurvey');

      if (showFeedbackSurvey !== null) {
        setShowSurveyModal(showFeedbackSurvey === 'true');
      } else {
        sessionStorage.setItem('showFeedbackSurvey', true);
        setShowSurveyModal(true);
      }
    };

    checkLocalStorage();
  }, []);

  useEffect(() => {
    const getSurveyPassStatus = () => {
      const isCompleted = user?.is_form_completed
      setIsSurveyPassed(isCompleted);
    };
    getSurveyPassStatus();
  }, [user]);

  const handleCloseDialog = () => {
    setShowSurveyModal(false);
    sessionStorage.setItem('showFeedbackSurvey', false);
  };

  const handleSubmit = async (value) => {
    const body = new FormData()
    for (const key in value) {
      body.append(key, value[key]);
    }
   
    await userAPIs
      .sendFeedback(body)
      .then(() => {
        createNotification('Опрос успешно пройден');
        localStorage.setItem('isFeedbackSurveyCompleted', true);
        setIsSurveyPassed(true);
        handleCloseDialog();
      })
      .catch((err) => {
        createErrorNotification('Что-то пошло не так');
        console.error(err);
      });
  };

  const fields = [
    {
      label: '1. Оцените удобство работы с интерфейсом сервиса (юзабилити)',
      startMarkLabel: 'Совершенно не удобно',
      endMarkLabel: 'Очень удобно',
      fieldName: 'usability',
    },
    {
      label: '2. Оцените качество работы механизма поиска сервиса',
      startMarkLabel: 'Очень низкое',
      endMarkLabel: 'Идеальное',
      fieldName: 'search_quality',
    },
    {
      label: '3. Оцените качество работы модуля расчета стоимости доставки товара',
      startMarkLabel: 'Очень низкое',
      endMarkLabel: 'Идеальное',
      fieldName: 'calculation_quality',
    },
    {
      label: '4. Оцените скорость поиска товаров сервисом',
      startMarkLabel: 'Очень низкая',
      endMarkLabel: 'Очень высокая',
      fieldName: 'search_speed',
    },
  ];
  return (
    <>
      <Dialog
        open={!isSurveyPassed && showSurveyModal && organizationId}
        sx={{
          '& .MuiPaper-root': {
            width: mobile ? '100%' : '632px',
            margin: mobile ? '0px' : '32px',
            height: mobile ? '100%' : 'fit-content',
            maxHeight: mobile ? '100%' : 'calc(100% - 64px)',
          },
        }}
      >
        <div
          className={`tw-flex tw-flex-col tw-pt-6 tw-pb-[33px] tw-bg-white tw-rounded-xl ${mobile && 'tw-h-[100vh]'}`}
        >
          <div
            className={`tw-flex tw-flex-row tw-justify-between tw-items-center ${
              mobile ? 'tw-pb-[15px] tw-px-[15px]' : 'tw-mb-8 tw-px-6'
            } `}
          >
            <h2 className="tw-text-[#191919] tw-leading-5 tw-font-semibold tw-text-base ">
              {mobile ? 'Опрос' : 'Опрос удовлетворенности работой в сервисе предиктивной аналитики'}
            </h2>
            {/* {mobile && (
              <Button variant="text" className="tw-w-fit tw-p-0" onClick={() => handleCloseDialog()}>
                <CloseIcon />
              </Button>
            )} */}
          </div>
          <Form onSubmit={handleSubmit}>
            {({ handleSubmit, form }) => (
              <>
                <form
                  onSubmit={handleSubmit}
                  className={`tw-flex tw-flex-col tw-text-[#191919] ${mobile && 'tw-h-[97%] tw-justify-between'}`}
                >
                  <div
                    className={`tw-overflow-y-auto  ${
                      mobile ? 'tw-max-h-[90%] tw-px-[15px]' : 'tw-max-h-[567px] tw-px-6'
                    } `}
                  >
                    {mobile && (
                      <p className="tw-text-sm tw-leading-[18px] tw-text-[#191919] tw-font-medium tw-mt-4 tw-mb-6">
                        Опрос удовлетворенности работой в сервисе предиктивной аналитики
                      </p>
                    )}
                    <div className="tw-flex tw-flex-col tw-gap-9">
                      {fields.map((field) => (
                        <RangeSelector
                          key={field.fieldName}
                          label={field.label}
                          startMarkLabel={field.startMarkLabel}
                          endMarkLabel={field.endMarkLabel}
                          fieldName={field.fieldName}
                        />
                      ))}
                      <div className="tw-flex tw-flex-col tw-items-start tw-gap-[10px] tw-text-[#191919] ">
                        <p className="tw-text-sm tw-leading-[18px] tw-font-semibold">
                          5. Опишите кратко опыт работы с сервисом, положительные и отрицательные моменты. Ваши
                          предложения по доработке сервиса.
                        </p>
                        <Field name="suggestions">
                          {({ input: { value, onChange } }) => (
                            <>
                              <TextField
                                required
                                name=""
                                className="tw-w-full"
                                multiline
                                rows={6}
                                onChange={(e) => onChange(e.target.value)}
                              />
                            </>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tw-flex tw-flex-row tw-items-center tw-justify-end tw-gap-6 ${
                      mobile ? 'tw-mt-5' : 'tw-mt-[56px]'
                    } tw-px-6`}
                  >
                    {/* <Button
                      className="tw-w-fit tw-px-0 tw-text-[#666] tw-text-sm hover:tw-bg-transparent"
                      variant="text"
                      onClick={() => handleCloseDialog()}
                    >
                      Отменить
                    </Button> */}
                    <Button className="tw-py-3 tw-px-4 tw-w-fit tw-text-sm tw-rounded-lg" type="submit">
                      Отправить
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Form>
        </div>
      </Dialog>
    </>
  );
};
