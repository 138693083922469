import { useCallback, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ScrollTopButton from '../../components/scroll-top-button';
import VisibilitySensor from 'react-visibility-sensor';

import GeneralLayout from '../../components/general-layout';
import ProductCard from '../../components/product-card';
import SearchFilters from '../../components/search-filters';
import TotalPriceCard from '../../components/total-price-card';
import CreateProjectCard from '../../components/create-project-card';
import {
  cleanProducts,
  getCategories,
  getProducts,
  getReport,
  setArchivedSources,
  startProcessingReport,
} from '../../store/actions/organizations';
import classes from './products.module.sass';
import AddProductPanel from '../../components/add-product-panel';
import { ConfirmModal } from '../../components/confirm-modal';
import eventBus from '../../utils/eventBus';
import { EVENTS } from '../../utils/constant';
import StatusReport from '../../components/status-report';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import RegionSelector from '../../components/region-selector';
import { useHistory } from 'react-router';
import Button from '../../components/button';
import ArchiveAll from '../../components/archive-all';
import { Grid } from '@mui/material';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import { projectsAPIs, reportsAPIs } from '../../services';
import Loader from '../../components/loader';
import { RequestLimitNotification } from '../../components/request-limit';
import moment from 'moment';
import { CheckCorrectProductsNames } from './components/CheckCorrectProductsNames';
import { useDialog } from '../../providers/dialog.provider';

let getProductsInterval = null;

export default function ProductsPage() {
  const location = useLocation();
  const history = useHistory();
  const dialog = useDialog()

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [regionId, setRegionId] = useState('');
  const [filters, setFilters] = useState({
    page_num: 1,
    page_size: 20,
  });
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [priceFilter, setPriceFilter] = useState('avg_price');
  const [isScrolledToProduct, setIsScrolledToProduct] = useState(false);
  const { id } = useParams();

  const products = useSelector((state) => state.organizations.productList);
  const report = useSelector((state) => state.organizations.selectedReport);
  const { categories } = useSelector((state) => state.organizations);
  const regions = useSelector((state) => state.auth.regions);

  const isOptimizing = report?.status === 'optimized'

  const setProductsInterval = useCallback(
    (page_size) => {
      clearInterval(getProductsInterval);
      getProductsInterval = setInterval(() => {
        getReport(id, {
          ...(regionId ? { region: filters.region } : {}),
          ...filters,
          page_num: 1,
          page_size,
        });
      }, 15000);
    },
    [filters, id, regionId]
  );

  const hasProcessingProducts = products.some((p) => ['parsing', 'initial'].includes(p.status));

  function handleLoadMore(isVisible) {
    if (isVisible && !isLoading && report) {
      const newFilters = {
        ...filters,
        page_num: filters.page_num + 1,
      };
      setFilters(newFilters);
      setIsLoading(true);
      getProducts(report.id, newFilters, true).then((newProducts) => {
        setIsLoading(false);
        setProductsInterval(products?.length + newProducts?.length);
        if (newProducts?.length < filters.page_size) {
          setCanLoadMore(false);
        }
      });
    }
  }

  const handleReloadReport = useCallback(() => {
    const newFilters = {
      ...filters,
      page_num: 1,
      page_size: 20,
    };
    setFilters(newFilters);
    setIsLoadingReport(true);
    setIsLoading(true);
    getReport(id, newFilters).then(({ products }) => {
      if (products?.length > 0) {
        setProductsInterval(products?.length);
      }
      if (products?.length < newFilters.page_size) {
        setCanLoadMore(false);
      } else {
        setCanLoadMore(true);
      }
      setIsLoadingReport(false);
      setIsLoading(false);
    }).catch((err) => {
      console.error(err)
    });
  }, [filters, id, setProductsInterval]);

  useEffect(() => {
    setArchivedSources([]);
    const query = queryString.parse(location.search);
    let newFilters = { ...filters, ...(regionId ? { region: regionId } : {}) };
    if (query.sort_by && query.sort_order) {
      newFilters = {
        ...newFilters,
        sort_by: query.sort_by,
        sort_order: query.sort_order,
      };
    }
    setFilters(newFilters);
    getReport(id, newFilters)
      .then(({ products }) => {
        if (products?.length > 0) {
          setProductsInterval(products?.length);
        }
        if (products?.length < filters.page_size) {
          setCanLoadMore(false);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        createErrorNotification('Что-то пошло не так');
      });

    eventBus.on(EVENTS.RELOAD_PRODUCTS, handleReloadReport);

    return () => {
      cleanProducts();
      clearInterval(getProductsInterval);
      setCanLoadMore(true);
      eventBus.off(EVENTS.RELOAD_PRODUCTS, handleReloadReport);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, location]);

  useEffect(() => {
    if (location && !isScrolledToProduct) {
      const query = queryString.parse(location.search);
      if (query.product_id) {
        const el = document.getElementById(`product-${query.product_id}`);
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
          setIsScrolledToProduct(true);
        } else {
          const bottom = document.getElementById('product-bottom-page');
          bottom.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
      }
    }
  }, [location, products, isScrolledToProduct]);

  const handleFilterChange = async (newFilters) => {
    if (!report) {
      return;
    }
    setFilters({ ...filters, ...newFilters });
    setIsLoading(true);
    await getProducts(report.id, newFilters);
    setIsLoading(false);
  };

  const notFound = products?.length === 0;

  useEffect(() => {
    clearInterval(getProductsInterval);
    setProductsInterval(products?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (report) {
      setRegionId(regions.find((region) => region.region_id === report.region_id)?.name);
    }
  }, [report, regions]);

  useEffect(() => {
    getCategories();
  }, []);

  const handleChangeRegionId = async (region_name) => {
    if (region_name === null) return;
    const region = regions.find((region) => region.name === region_name);
    if (!region) return;
    if (region.name === regionId.name) return;
    setRegionId(region_name);
    await startProcessingReport(report.id, region.region_id)
      .then(() => createNotification('Регион доставки изменён'))
      .catch((err) => {
        console.error(err);
        createErrorNotification('Что-то пошло не так');
      });
  };
  const handleAddressChange = async (address, postalCode) => {
    try {
      await reportsAPIs.updateAddress(report.id, address, postalCode).then(
        async () =>
          await startProcessingReport(report.id, report.region_id)
            .then(() => createNotification('Регион доставки изменён'))
            .catch((err) => {
              console.error(err);
              createErrorNotification('Что-то пошло не так');
            })
      );
    } catch (err) {
      console.error(err);
      createErrorNotification('Что-то пошло не так');
    }
  };

  const stopSearch = useCallback(async () => {
    await projectsAPIs.stopSearch(report?.project_id).catch((err) => {
      console.error(err);
    });
  }, [report]);

  const openOptimizingModalForm = useCallback(() => {
    dialog.open(<CheckCorrectProductsNames />)
  }, [dialog])

  return (
    <GeneralLayout>
      {isOptimizing && openOptimizingModalForm()}
      <h2 className="tw-text-[#191919] tw-font-semibold tw-leading-[38px] tw-text-[32px] tw-mb-[7px]">
        Проект {report?.project_name}
      </h2>
      {report?.created_at && (
        <p className="tw-mb-[29px] tw-text-[#666666] tw-text-sm tw-leading-[18px]">Дата создания {moment(report.created_at).format('DD.MM.YYYY')}</p>
      )}
      <div className="tw-mb-[40px]">
        <RequestLimitNotification stopCallback={stopSearch} isSearching={report?.status === 'processing'} />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={9}>
          <div className="tw-flex tw-flex-col tw-w-full">
            <AddProductPanel
              reportId={report?.id}
              showUploadFile={!(notFound && !isLoading)}
              onReloadProducts={handleReloadReport}
            />

            <div className="lg:tw-hidden">
              <TotalPriceCard
                productAmount={products?.length}
                topELements={
                  <div className={classes.buttonGroup}>
                    {/* {report && report.has_archived_report && (
                      <Button size="small" secondary onClick={() => history.push(`/archived-projects/${id}`)}>
                        Перейти в архивные отчеты
                      </Button>
                    )} */}
                    {/* <GenerateReport report={report} onReloadProducts={handleReloadReport} /> */}
                    {/* <ArchiveAll report={report} onReloadProducts={handleReloadReport} isLoading={isLoading} /> */}
                  </div>
                }
                regionId={regions.find((region) => region.name === regionId)?.region_id}
                priceFilter={priceFilter}
                isLoading={hasProcessingProducts}
                report={report}
                regionPicker={
                  <RegionSelector
                    isLoading={hasProcessingProducts}
                    report={report}
                    regions={regions}
                    onChangeRegionId={handleChangeRegionId}
                    handleFilterChange={handleFilterChange}
                    handleChangeAddress={handleAddressChange}
                  />
                }
              />
              {/* <StatusReport priceFilter={priceFilter} isLoading={hasProcessingProducts} report={report} /> */}
            </div>
            {!notFound && (
              <StatusReport
                priceFilter={priceFilter}
                isLoading={hasProcessingProducts}
                report={report}
                onRestart={handleReloadReport}
              />
            )}
            {!notFound && (
              <SearchFilters
                reportId={id}
                filters={filters}
                onChange={handleFilterChange}
                setPriceFilter={setPriceFilter}
              />
            )}

            {!notFound && <ScrollTopButton />}
            {products.map((product) => (
              <ProductCard
                regionName={regionId}
                key={product.id}
                product={product}
                priceFilter={priceFilter}
                categories={categories}
                onReloadProducts={handleReloadReport}
              />
            ))}
            <div id="product-bottom-page" style={{ height: 40 }}></div>
            {canLoadMore && (
              <VisibilitySensor onChange={handleLoadMore}>
                <Loader />
              </VisibilitySensor>
            )}

            {notFound && !isLoading && (
              <Col xs={12}>
                <CreateProjectCard onReloadProducts={handleReloadReport} />
              </Col>
            )}
            <ConfirmModal title="Вы уверены, что хотите удалить этот продукт?" />
          </div>
        </Grid>
        <Grid item lg={3}>
          <div className="lg:tw-flex tw-flex-col tw-hidden">
            <RegionSelector
              isLoading={hasProcessingProducts}
              report={report}
              regionId={regionId}
              onChangeRegionId={handleChangeRegionId}
              handleFilterChange={handleFilterChange}
              handleChangeAddress={handleAddressChange}
            />
            {!notFound && (
              <>
                {/* <RequestPrice report={report} onReloadProducts={handleReloadReport} /> */}
                <TotalPriceCard
                  productAmount={products?.length}
                  handleChangeAddress={handleAddressChange}
                  topELements={
                    <div className={classes.buttonGroup}>
                      {/* {report && report.has_archived_report && (
                        <Button size="small" secondary onClick={() => history.push(`/archived-projects/${id}`)}>
                          Перейти в архивные отчеты
                        </Button>
                      )} */}
                      {/* <GenerateReport report={report} onReloadProducts={handleReloadReport} /> */}
                      {/* <ArchiveAll report={report} onReloadProducts={handleReloadReport} /> */}
                    </div>
                  }
                  regionId={regions.find((region) => region.name === regionId)?.region_id}
                  priceFilter={priceFilter}
                  isLoading={hasProcessingProducts}
                  report={report}
                />
              </>
            )}
          </div>
        </Grid>
      </Grid>
      <div className="tw-flex tw-flex-row tw-gap-6"></div>
    </GeneralLayout>
  );
}
